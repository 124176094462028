import './App.css';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  // alpha
} from '@mui/material';
import ProfilePic from './images/profile.jpg'
import CoverPic from './images/unicorn.JPG'
import MailIcon from '@mui/icons-material/Mail'
import WorkIcon from '@mui/icons-material/Work'
import StoreIcon from '@mui/icons-material/Store'
import BusinessIcon from '@mui/icons-material/Business'
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu'
import PhoneIcon from '@mui/icons-material/Phone'

function App() {
  return (
    // <div
    //   style={{
    //     backgroundColor: '#025464',
    //     // minHeight: '100vh',
    //     border: '3px groove #F8E8EE'
    //   }}
    // >
    //   <Box
    //     display={'flex'}
    //     justifyContent={'center'}
    //     sx={{
    //       py: 1
    //     }}
    //   >
    //     <Typography
    //       sx={{
    //         fontSize: 36,
    //         fontWeight: 'bold',
    //         color: '#E3F4F4'
    //       }}
    //     >
    //       Character
    //     </Typography>
    //   </Box>
    //   <Box
    //     display={'flex'}
    //     justifyContent={'center'}
    //     sx={{
    //       pb: 2
    //     }}
    //   >
    //     <img
    //       src={ProfilePic}
    //       alt='Profile'
    //       width={150}
    //       height={150}
    //       style={{ border: '2px solid #E3F4F4' }}
    //     />
    //   </Box>
    //   <Box
    //     display={'flex'}
    //     justifyContent={'center'}
    //     sx={{
    //       border: 1,
    //       borderColor: '#E3F4F4',
    //       mx: 3
    //     }}
    //   >
    //     <Typography
    //       sx={{
    //         fontSize: 30,
    //         color: '#E3F4F4'
    //       }}
    //     >
    //       2nd Floor Garden
    //     </Typography>
    //   </Box>
    //   <Box
    //     sx={{
    //       border: 1,
    //       borderColor: '#E3F4F4',
    //       mx: 3,
    //       mb: 2
    //     }}
    //   >
    //     <Typography
    //       sx={{
    //         fontSize: 18,
    //         color: '#E3F4F4',
    //         ml: 2
    //       }}
    //     >
    //       Name : Arisu
    //     </Typography>
    //     <Typography
    //       sx={{
    //         fontSize: 18,
    //         color: '#E3F4F4',
    //         ml: 2
    //       }}
    //     >
    //       Level : 28
    //     </Typography>
    //     <Typography
    //       sx={{
    //         fontSize: 18,
    //         color: '#E3F4F4',
    //         ml: 2
    //       }}
    //     >
    //       Guild : Guild Master
    //     </Typography>
    //   </Box>
    // </div>
    <Card sx={{ boxShadow: 'none', bgcolor: 'ButtonFace', minHeight: '100vh' }}>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component={'img'}
          height={190}
          image={CoverPic}
          alt='Cover'
          sx={{
            objectFit: 'cover',
            // position: 'relative'
          }}
        />
        <Avatar
          alt="Profile"
          src={ProfilePic}
          sx={{
            ml: 1,
            mb: 1,
            width: 120,
            height: 120,
            position: 'absolute',
            bottom: 0,
            left: 0,
            border: '2px solid #E3F4F4'
          }}
        />
        {/* <Box
          sx={{
            mb: 2,
            position: 'absolute',
            bottom: 0,
            left: 150,
            border: '2px solid #E3F4F4',
            borderRadius: 1,
            bgcolor: alpha('#F8F6F4', 0.7)
          }}
        >
          <Box sx={{ p: 1 }}>
            <Typography sx={{ fontSize: 18, color: '#025464' }}>Arisu</Typography>
            <Typography sx={{ fontSize: 14, color: '#025464' }}>Full Stack Python Developer</Typography>
            <Typography sx={{ fontSize: 16, color: '#025464' }}>ariya.rk@outlook.com</Typography>
          </Box>
        </Box> */}
      </Box>
      <CardContent>
        <Box display={'flex'} justifyContent={'center'}>
          <Typography sx={{ fontSize: 24, color: '#025464' }}>Arisu</Typography>
        </Box>
        <Box sx={{ mb: 1 }} display={'flex'} justifyContent={'center'}>
          <Typography sx={{ fontSize: 18, color: '#025464' }}>Full Stack Python Developer</Typography>
        </Box>
        <Divider sx={{ mt: 2, mb: 1, mx: 2 }} />
        <Box display={'flex'} justifyContent={'center'}>
          <Typography sx={{ fontSize: 20, color: '#025464' }}>Contract</Typography>
        </Box>
        <Box sx={{ mx: 4 }} display={'flex'} alignItems={'center'}>
          <WorkIcon sx={{ fontSize: 20, color: '#025464', mr: 1 }}/>
          <Typography sx={{ fontSize: 18, color: '#025464' }}>2nd Floor Garden</Typography>
        </Box>
        <Box sx={{ mx: 4 }} display={'flex'} alignItems={'center'}>
          <PhoneIcon sx={{ fontSize: 20, color: '#025464', mr: 1 }}/>
          <Typography sx={{ fontSize: 16, color: '#025464' }}>{"+66(0) 646051136"}</Typography>
        </Box>
        <Box sx={{ mx: 4 }} display={'flex'} alignItems={'center'}>
          <MailIcon sx={{ fontSize: 20, color: '#025464', mr: 1 }}/>
          <Typography sx={{ fontSize: 18, color: '#025464' }}>ariya.rk@outlook.com</Typography>
        </Box>
        <Divider sx={{ mt: 2, mb: 1, mx: 2 }} />
        <Box display={'flex'} justifyContent={'center'}>
          <Typography sx={{ fontSize: 20, color: '#025464' }}>Founder</Typography>
        </Box>
        <Box sx={{ mx: 4 }} display={'flex'} alignItems={'center'}>
          <StoreIcon sx={{ fontSize: 20, color: '#025464', mr: 1 }}/>
          <Typography sx={{ fontSize: 16, color: '#025464' }}>Arisu</Typography>
        </Box>
        <Box sx={{ mx: 4 }} display={'flex'} alignItems={'center'}>
          <RestaurantMenuIcon sx={{ fontSize: 20, color: '#025464', mr: 1 }}/>
          <Typography sx={{ fontSize: 16, color: '#025464' }}>Assata</Typography>
        </Box>
        <Box sx={{ mx: 4 }} display={'flex'} alignItems={'center'}>
          <BusinessIcon sx={{ fontSize: 20, color: '#025464', mr: 1 }}/>
          <Typography sx={{ fontSize: 16, color: '#025464' }}>2nd Floor Garden</Typography>
        </Box>
        <Divider sx={{ mt: 2, mb: 1, mx: 2 }} />
      </CardContent>
    </Card>
  );
}

export default App;
